export const localization = {
    en: {
        menu: {
            aboutProduct: 'About product',
            aboutCompany: 'About company',
            vacancy: 'Vacancy',
            demo: 'Demo versions',
            documentation: 'Documentation',
            getIsFront: 'Get isFront',
        }
    },
    ru: {
        menu: {
            aboutProduct: 'О продукте',
            aboutCompany: 'О компании',
            vacancy: 'Вакансии',
            demo: 'Демо-версии',
            documentation: 'Документы',
            getIsFront: 'Получить isFront 2.0',
        },
        titleTail: ' — Информационные системы',
        page: {
            notFound: {
                title: 'Страница не найдена',
                metaTitle: 'Страница не найдена',
                text: `<p>Вы попали на несуществующую страницу.</p>
                       <p>Проверьте, правильно ли вы ввели адрес страницы.</p>`,
                back: 'Вернуться на главную'
            },
            integrationsTitle: 'Наши внедрения',
            product: {
                title: 'Современный банкинг нового поколения',
                metaTitle: 'О продукте',
                subtitle: 'Усовершенствуйте свой банк с дистанционным банковским обслуживанием isFront 2.0!',
                bannerButton: 'Хочу ДБО isFront 2.0',
                statistic: {
                    years1: '15 лет',
                    years2: 'на рынке',
                    clients1: '70 +',
                    clients2: 'внедрений',
                    support1: '24/7',
                    support2: 'поддержка',
                    banks1: '1,5 млн +',
                    banks2: 'клиентов банков',
                    team1: '70',
                    team2: 'членов команды'
                },
                functional: {
                    title: '<span class="text-blue">Функциональность</span>',
                    text: 'Реализуем весь необходимый функционал &ndash; онбординг, ЕБС, Цифровой рубль, УКЭП, кредитные договоры и многое другое.<br><br>' +
                        'isFront 2.0 независим от иностранных магазинов приложений. С помощью технологий flutter и pwa предоставляем единый функционал и интерфейс для всех платформ.'
                },
                design: {
                    title: '<span class="text-blue">Стильный</span> дизайн',
                    text: 'Мы систематизировали знания о клиентском опыте и собрали лучшие практики UI и UX в свою дизайн-систему. <br><br>' +
                        'Это библиотека, которая включает готовые элементы интерфейса в коде и группы шаблонов в фирменном стиле вашего банка'
                },
                safety: {
                    title: 'Надёжная <span class="text-blue">защита</span>',
                    text: 'Мы внимательно отслеживаем изменения в основополагающих документах, положениях Банка России 683-П, 821-П. На регулярной основе проводим оценку соответствия системы ДБО isFront 2.0 по оценочному уровню доверия не ниже ОУД4 в лицензированной испытательной лаборатории системы сертификации ФСТЭК.'
                },
                modernTechs: {
                    title: '<span class="text-blue">Современные</span> технологии',
                    text: 'ДБО isFront 2.0 реализует весь необходимый функционал – от классических платежей до сложных внешнеторговых валютных контрактов. <br>' +
                        'Уникальность системы в ее мощной реализации функционала физических и юридических лиц в одном ДБО. Клиентам это дает удобное переключение между ролями в одном интерфейсе. Банку – сокращение расходов на поддержание разных систем ДБО.',
                    devTools: 'Разработка',
                    techs: 'Системные технологии'
                },
                dboSteps: {
                    title: '4 шага к новому ДБО',
                    step1: {
                        title: 'Первичный <span class="text-blue">контакт</span>',
                        text: 'Вы оставляете заявку на сайте, мы связываемся с вами, определяем задачу проекта и высылаем референсы бесплатно'
                    },
                    step2: {
                        title: 'Брифинг и <span class="text-blue">договор</span>',
                        text: 'Обсуждаем все вопросы по заказу, определяем четкие сроки, производим расчет и заключаем договор'
                    },
                    step3: {
                        title: '<span class="text-blue">Старт</span> работ',
                        text: 'В установленный срок высылаем результат работ'
                    },
                    step4: {
                        title: 'Итоговый <span class="text-blue">результат</span>',
                        text: 'Закрываем конкретную задачу вашего бизнеса, и вы наслаждаетесь итогом'
                    }
                },
                review: {
                    title: 'Отзывы',
                    reviews: [
                        {
                            bankSrc: '/assets/banks-logo/kontur-review.png',
                            text: 'Выражаем Вам благодарность за слаженную работу Вашей команды по вопросам внедрения функционала проведения операций в формате B2B с помощью Системы быстрых платежей Банка России (далее - СБП), позволившее ПАО “Контур.Банк” (далее - Банк) исполнить в срок требования, установленные законодательством РФ по развитию бизнес-платежей с помощью СБП.</br>' +
                                'Хотелось бы отметить, что на протяжении 6 лет нашей совместной и плодотворной работы по развитию дистанционной системы интернет-сервиса для физических и юридических лиц “isFront” в Банке, Ваша команда показала эффективную и оперативную работу по решению бизнес задач Банка в плане расширения сервисов дистанционного банковского обслуживания для удобства наших Клиентов.',
                            footer: 'Павлов А. М., Президент ПАО “Контур.Банк”'
                        },
                        {
                            bankSrc: '/assets/banks-logo/rdb-review.svg',
                            text: 'Благодаря слаженной и взаимовыгодной работе с ООО «Информационные системы» банк систематически представляет своим клиентам новые удобные и технологичные сервисы дистанционного банковского обслуживания. Сотрудничество между нашими компаниями позволяет обоюдно развивать бизнес, привносит' +
                                ' дополнительный и эксклюзивный функционал в банковскую отрасль. Надеемся на продолжение плодотворного взаимодействия с ООО «Информационные системы» по всем направлениям.</br>' +
                                'Желаю Вам и коллективу компании процветания и реализации новых успешных проектов!',
                            footer: 'Первый Заместитель Председателя Правления РосДорБанк А.В. Виноградов'
                        },
                        {
                            bankSrc: '/assets/banks-logo/vtb-review.png',
                            text: 'Спасибо большое всей команде Информационных систем и всем принимавшим участие в обновлении интернет и мобильного банка для юридических лиц в дочернем банке группы ВТБ. Надеюсь на активную цифровизацию продуктов и сервисов СМБ во всей группе дочерних банков ВТБ.',
                            footer: 'Хамракулов Д.Е.<br>Управляющий директор. Служба координации дочерних банков ОАО Банк ВТБ'
                        },
                        {
                            bankSrc: '/assets/banks-logo/sinara-review.png',
                            text: 'За всё то время, которое мы сотрудничаем - мы прошли большой путь внедрения и развития системы ДБО для банков: СКБ-Банк, Газэнергобанк, Делобанк. <br>' +
                                'И с Инфосисом мы получили главное - свободу действий и вовлечённость в созидание нашей системы, вополощении наших идей в жизнь.',
                            footer: 'Клепинин А.В., генеральный директор'
                        },
                        {
                            bankSrc: '/assets/banks-logo/nbd-review.png',
                            text: 'ПАО “НБД-Банк” выражает Вам благодарность за продуктивное сотрудничество. Особенно хотелось бы отметить проект по подключению к Системе Быстрых Платежей, который реализован Вашей компанией оперативно и качественно. Надеемся, что и в дальнейшем мы продолжим успешное сотрудничество. Желаем Вам профессиональных успехов и процветания.',
                            footer: 'Шаронов А.Г., председатель правления'
                        },
                        {
                            bankSrc: '/assets/banks-logo/priovtb-review.png',
                            text: 'Выражаю Вам и команде поддержки Вашей компании благодарность за систематическое, качественное и в нужный банку срок обновление ДБО isFront на наших серверах с учетом регламента и самых строгих мер безопасности! В связи с оперативным и качественным обновлением систем ДБО как для физических, так и для юридических лиц клиенты банка всегда получают стабильно высокий уровень дистанционного сервиса нашего банка.</br>' +
                                'Желаю Вашей компании процветания и внедрения новых инновационнах услуг у ваших заказчиков!',
                            footer: 'Дронов А. Е., Председатель Правления'
                        },
                    ]
                },
            },
            company: {
                title: 'О компании',
                metaTitle: 'О компании',
                banner: {
                    title: 'цифровое банковское будущее здесь и сейчас',
                    subtitle: 'Комплексно подходим к разработке ДБО.',
                    button: 'Получить isFront 2.0'
                },
                whoWe: {
                    title: 'Кто мы?',
                    text: 'Информационные системы – отечественный разработчик систем ДБО. ' +
                        'Являемся вендорами НСПК СБП с 2018 года, а также разработчиками первой и второй волны цифрового рубля.' +
                        '<br><br>' +
                        'Система ДБО isFront 2.0 за 15 лет впитала в себя с рынка самые передовые решения, самый востребованный функционал. ' +
                        'И это не просто слова, это дело всей нашей жизни, в которое мы вложили свой опыт и знания. '
                },
                principlesTitle: 'Наши принципы',
                principles: [
                        {
                            title: 'Инновации',
                            text: 'Результат инвестирования интеллектуального труда во внедрение новых технологий, пользовательского опыта и дизайна для обеспечения непревзойденного удобства и безопасности клиентов банка.'
                        },
                        {
                            title: 'Сотрудничество',
                            text: 'Нам важен результат совместной деятельности для того, чтобы реализовать задуманное клиентом и воплотить в работающий функционал.'
                        },
                        {
                            title: 'Функциональность',
                            text: 'Более 70 внедрений обеспечили ДБО isFront богатой функциональностью. ',
                        },
                        {
                            title: 'Развитие',
                            text: 'Скорость реализации, свобода в развитии и успешная цифровизация банка!'
                        },
                        {
                            title: 'Ответственность',
                            text: 'Держим слово, дорожим доверием клиентов!'
                        },
                        {
                            title: 'Надежность',
                            text: 'Компетенция компании как надежного поставщика безопасных решений подтверждена лицензиями ФСТЭК и ФСБ.'
                        },
                        {
                            title: 'Технологичность',
                            text: 'Система ДБО isFront 2.0 включена в реестр отечественного ПО.'
                        }
                    ],
                work: {
                    title: 'Как мы работаем?',
                    steps: [
                        {
                            title: 'Сбор <span class="text-blue">требований</span>',
                            text: 'Брифинг, встреча и обсуждение проекта, сбор требований, подготовка документации.'
                        },
                        {
                            title: '<span class="text-blue">Прототипирование</span> и <span class="text-blue">ТЗ</span>',
                            text: 'Разработка прототипов всех страниц ДБО, проектирование структуры, пользовательских сценариев, проработка логических цепочек, технических требований к проекту.'
                        },
                        {
                            title: '<span class="text-blue">Дизайн</span>',
                            text: 'Разработка дизайн-системы ДБО. Подготовка графических элементов: стили, шрифты, блоки, отступы, иконки. Отрисовка страниц проекта. '
                        },
                        {
                            title: 'Верстка, <span class="text-blue">программирование</span>, тестирование',
                            text: 'Создание стилей, подключение скриптов и библиотек. Адаптация под разные устройства. Настройка системы управления ДБО и базы данных. Программирование модулей и сервисов, тестирование и отладка.'
                        },
                        {
                            title: '<span class="text-blue">Запуск</span> ДБО',
                            text: 'Набор образов серверов для системы виртуализации. Подключение к сервисам банка и межсервисное взаимодействие. Установка и запуск начальной версии ДБО.'
                        },
                        {
                            title: 'Выдача клиенту и <span class="text-blue">поддержка ДБО</span> на бою',
                            text: 'Тестирование версий и поиск ошибок. Мониторинг работоспособности сервисов. Дальнейшее сопровождение.'
                        }
                    ]
                }
            },
            vacancies: {
                title: 'Вакансии',
                metaTitle: 'Вакансии',
                more: 'Подробнее',
                respond: 'Оставить заявку',
                respondHH: 'Откликнуться на hh.ru',
                curl: 'Свернуть',
                banner: {
                  title: 'Будем знакомы!',
                  subtitle: 'Компания “Информационные системы” была основана в 2009 году. Мы занимаемся разработкой дистанционного банковского обслуживания.',
                },
                team: {
                  title: 'Наша команда',
                  text: 'Это команда <span class="text-blue">профессионалов</span> в области графического и web-дизайна, frontend и backend разработки.',
                },
                conditions: [
                    {
                        title: '<span class="text-blue">Удобный</span> офис',
                        text: 'современное оборудование'
                    },
                    {
                        title: 'Занятия <span class="text-blue">спортом</span>',
                        text: 'проходят каждый день, зал находится в офисе'
                    },
                    {
                        title: '<span class="text-blue">Знаковые</span> проекты',
                        text: 'для лидеров отрасли'
                    },
                    {
                        title: 'Регулярные <span class="text-blue">премии</span>',
                        text: 'годовые и ежемесячные по результатам работы'
                    },
                    {
                        title: '<span class="text-blue">Обучения</span> и <span class="text-blue">тренинги</span>',
                        text: 'внутри компании, а также посещение бизнес-семинаров\n'
                    },
                    {
                        title: 'Белая оплата труда',
                        text: 'официальная заработная плата, оплата отпусков и больничных'
                    }
                ],
                list: {
                    designer: {
                        name: 'Дизайнер',
                        description: 'Ты креативный человек, а Figma и Photoshop твой второй дом? Если тебе нравится работать в\n' +
                            'динамичной среде, где ценится твой вклад и творческий подход — мы будем рады видеть\n' +
                            'тебя в нашем коллективе!',
                    },
                    'tech-writer': {
                        name: 'Технический <span class="text-blue">писатель</span>',
                        description: 'Ты привык докапываться до сути и анализировать большие объемы информации? Ты умеешь\n' +
                            'создавать документацию по технически сложному продукту на понятном для пользователя\n' +
                            'языке? Тебе предстоит переводить текст с языка разработчиков на пользовательский,\n' +
                            'взаимодействовать со всей командой. Мы ждем тебя в нашей компании!',
                        requirements: [
                            '<div class="text-blue">Обязанности</div>' +
                            '–  Создание структурированной и понятной документации для внутреннего и внешнего пользования;<br />' +
                            '–  Разработка описания алгоритмов и пользовательских интерфейсов;<br />' +
                            '–  Взаимодействие с разработчиками, аналитиками, тестировщиками и экспертами предметных областей для сбора информации;<br />' +
                            '–  Изучение принципов и правил работы информационных систем в рамках проекта;<br />' +
                            '–  Написание и доработка документации на реализованное и разрабатываемое ПО согласно ГОСТам.<br />' +
                            '<div class="text-blue">Требования</div>' +
                            '–  Высшее ТЕХНИЧЕСКОЕ образование (web-программиста, математик, инженер), приветствуется дополнительное образование по банковскому профилю: банковское дело, финансы и кредит, экономика;<br />' +
                            '–  Опыт работы в области IT в качестве аналитика/ технолога/ постановщика задач/ web-разработчика от 1 года;<br />' +
                            '–  Приветствуется знание банковских технологий;<br />' +
                            '–  Опыт написания и корректировки технической документации (руководства, инструкции, регламенты, API и т.п.) в соответствии с различными стандартами;<br />' +
                            '–  Опыт написания документации в ИТ проектах, наличие технического бэкграунда;<br />' +
                            '–  Знание технической и ИТ-терминологии;<br />' +
                            '–  Грамотная устная и письменная речь, способность связно излагать материал и четко формулировать мысли, иллюстрировать и структурировать информацию в удобном для восприятия виде, технически грамотно;<br />' +
                            '–  Работа с большими массивами информации;<br />' +
                            '–  Технический английский (свободное чтение рекомендуется, чтение со словарем - обязательно);<br />' +
                            '–  Способность воспринимать большие объёмы информации, готовность к обучению, умение работать в команде.<br />' +
                            '<div class="text-blue">Условия</div>' +
                            '–  С вас высокий результат и четкое выполнение всех задач в срок;<br />' +
                            '–  Испытательный срок;<br />' +
                            '–  Рассматриваются кандидаты только на полный день;<br />' +
                            '–  Конкурентная заработная плата (белая), официальное трудоустройство;<br />' +
                            '–  Годовые и ежемесячные премии по результатам работы;<br />' +
                            '–  Обучение и тренировки (внутри компании, а также посещение бизнес-семинаров, тренингов);<br />' +
                            '–  Мы ищем тех, кто реально хочет работать. Тех, кому важен рост знаний и компетенций. Тех, для кого работа – это важная часть жизни для достижения успеха и самодостаточности;<br />' +
                            '–  Любителей создавать видимость работы, просьба не беспокоиться;<br />' +
                            '–  Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри компании.'
                        ],
                    },
                    avtotest: {
                        name: 'Разработчик <span class="text-blue">автотестов</span>',
                        description: 'Ты - сторонник высокого качества и готов разрабатывать методы его обеспечения?\n' +
                            'Ты умеешь анализировать результаты тестирования и составлять отчеты о найденных\n' +
                            'ошибках?\n' +
                            'Ты привык ценить не только свое время, но и время других людей?\n' +
                            'Тебе предстоит разрабатывать систему автоматизированного тестирования нашего продукта\n' +
                            'для большого количества мобильных устройств, браузеров и операционных систем, работать\n' +
                            'в команде и улучшать качество ПО. Мы ждем тебя в нашей компании!',
                        requirements: [
                            '<div class="text-blue">Обязанности</div>' +

                            '–  Автоматизация процесса тестирования;</br>' +
                            '–  Запуск тестов;</br>' +
                            '–  Анализ результатов теста;</br>' +
                            '–  Создание новых кейсов;</br>' +
                            '–  Поддержка и актуализация существующих кейсов;</br>' +
                            '–  Взаимодействие с разработчиками.</br>' +

                            '<div class="text-blue">Требования</div>' +

                            '–  Основы работы с базами данных и SQL, Git;</br>' +
                            '–  Основы работы с JavaScript/TypeScript, HTML, CSS;</br>' +
                            '–  Английский язык на уровне чтения технической документации;</br>' +
                            '–  Желательно знание Playwright, JUnit, Spock, Jenkins.</br>' +

                            '<div class="text-blue">Условия</div>' +

                            '–  С вас высокий результат и четкое выполнение всех задач в срок;</br>' +
                            '–  Испытательный срок;</br>' +
                            '–  Рассматриваются кандидаты только на полный день;</br>' +
                            '–  Конкурентная заработная плата (белая), официальное трудоустройство;</br>' +
                            '–  Годовые и ежемесячные премии по результатам работы;</br>' +
                            '–  Обучение и тренировки (внутри компании, а также посещение бизнес-семинаров, тренингов);</br>' +
                            '–  Мы ищем тех, кто реально хочет работать. Тех, кому важен рост знаний и компетенций. Тех, для кого работа – это важная часть жизни для достижения успеха и самодостаточности;</br>' +
                            '–  Любителей создавать видимость работы, просьба не беспокоиться;</br>' +
                            '–  Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри компании.'
                        ],
                    },
                    administrator: {
                        name: 'Специалист по <span class="text-blue">технической поддержке</span> ДБО (QA-инженер, системный аналитик)',
                        description: 'Если ты умеешь слушать и эффективно решать вопросы пользователя, если ты умеешь\n' +
                            'смотреть на задачу с разных сторон и находить оптимальный путь для ее решения, если ты\n' +
                            'терпелив и любишь общаться с людьми, то мы ждем тебя в нашей компании!\n' +
                            'Тебе предстоит решать технические задачи, заниматься сопровождением нового и\n' +
                            'существующего ПО, находить ошибки в программе через неочевидные сценарии.',
                        requirements: [
                            '<div class="text-blue">Обязанности</div>' +

                            '–  Сопровождение систем ДБО, администрирование системы "isFront";</br>' +
                            '–  Работа с клиентами – банками: прием обращений о проблемах систем ДБО и их решение;</br>' +
                            '–  Участие в написании технической документации, инструкций, порядков, регламентов;</br>' +
                            '–  Тестирование нового ПО, диагностика ошибок, разбор багов.</br>' +
                            '–  Внедрение и сопровождение ПО;</br>' +
                            '–  Анализ и написание постановок (ТЗ) по запросам клиентов.</br>' +

                            '<div class="text-blue">Требования</div>' +

                            '–  Высшее техническое образование, неоконченное высшее, средне-специальное (программист, математик, инженер, экономист, менеджер);</br>' +
                            '–  Умение писать простые SQL-запросы;</br>' +
                            '–  Навыки системного аналитика, опыт составления ТЗ – обязательно;</br>' +
                            '–  Приветствуется знание банковской бухгалтерии и банковских бизнес-процессов, банковских продуктов, банковских технологий;</br>' +
                            '–  Желательно знание принципов информационной безопасности в платежных системах, ориентирование в понятиях ЭП, шифрования, секретный/открытый ключи;</br>' +
                            '–  Приветствуется опыт сопровождения систем ДБО (BS-Client v.3 - БСС, или любого другого разработчика);</br>' +
                            '–  Английский язык на уровне чтения профессиональной литературы;</br>' +
                            '–  Опыт работы с ДБО в качестве администратора или специалиста технической поддержки - является приоритетным в рассмотрении резюме соискателей.</br>' +

                            '<div class="text-blue">Условия</div>' +

                            '–  С вас высокий результат и четкое выполнение всех задач в срок;</br>' +
                            '–  Испытательный срок;</br>' +
                            '–  Рассматриваются кандидаты только на полный день;</br>' +
                            '–  Конкурентная заработная плата (белая), официальное трудоустройство;</br>' +
                            '–  Годовые и ежемесячные премии по результатам работы;</br>' +
                            '–  Обучение и тренировки (внутри компании, а также посещение бизнес-семинаров, тренингов);</br>' +
                            '–  Мы ищем тех, кто реально хочет работать. Тех, кому важен рост знаний и компетенций. Тех, для кого работа – это важная часть жизни для достижения успеха и самодостаточности;</br>' +
                            '–  Любителей создавать видимость работы, просьба не беспокоиться;</br>' +
                            '–  Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри компании.'
                        ],
                    },
                    'developer-java': {
                        name: '<span class="text-blue">Программист-</span>разработчик <span class="text-blue">Java</span>',
                        description: 'Для тебя важен результат и ты умеешь превращать идеи в код? Тебе предстоит решать\n' +
                            'технические задачи, связанные с интеграцией, разрабатывать новый функционал, заниматься\n' +
                            'рефакторингом и оптимизацией высоко-нагруженных систем. Мы ищем Java-разработчика,\n' +
                            'который готов работать над передовыми проектами в области дистанционного банковского\n' +
                            'обслуживания.',
                        requirements: [
                            '<div class="text-blue">Обязанности</div>' +

                            '–  Разработка системы Дистанционного Банковского Обслуживания</br>' +

                            '<div class="text-blue">Ты должен знать</div>' +

                            '–  JAVA;</br>' +
                            '–  Уверенное владение HTML, CSS, JS;</br>' +
                            '–  Опыт создания Web-приложений (понимание соответствующего стека Java-технологий, знание протоколов TCP/IP, HTTP/HTTPS, знание JavaScript и HTML);</br>' +
                            '–  Дополнительно приветствуется знание Angular, Flutter;</br>' +
                            '–  Хорошие навыки (чтение технической документации) английского языка.</br>' +

                            '<div class="text-blue">Требования</div>' +

                            '–  Образование: высшее или неполное высшее, возможно средне-специальное.</br>' +
                            '–  Опыт разработки: JAVA не менее 1 года (рассматриваем так же программистов без опыта работы в компаниях, но с опытом самостоятельного программирования);</br>' +
                            '–  Умение работать в команде и решать задачи самостоятельно.</br>' +

                            '<div class="text-blue">Условия</div>' +

                            '–  С вас высокий результат и четкое выполнение всех задач в срок;</br>' +
                            '–  Испытательный срок;</br>' +
                            '–  Рассматриваются кандидаты только на полный день;</br>' +
                            '–  Конкурентная заработная плата (белая), официальное трудоустройство;</br>' +
                            '–  Годовые и ежемесячные премии по результатам работы;</br>' +
                            '–  Обучение и тренировки (внутри компании, а также посещение бизнес-семинаров, тренингов);</br>' +
                            '–  Мы ищем тех, кто реально хочет работать. Тех, кому важен рост знаний и компетенций. Тех, для кого работа – это важная часть жизни для достижения успеха и самодостаточности;</br>' +
                            '–  Любителей создавать видимость работы, просьба не беспокоиться;</br>' +
                            '–  Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри компании.'
                        ],
                    },
                    sysadmin: {
                        name: 'Системный администратор',
                        description: 'Ты готов быть всегда начеку для того, чтобы вовремя среагировать на угрозу? Тебе нравится\n' +
                            'работать с документацией и писать технические задания на разработку ПО? Тебе предстоит\n' +
                            'регулярно проводить пентесты, администрировать средства защиты информации и\n' +
                            'заниматься технической поддержкой пользователей. Мы ждем тебя в нашей компании!',
                    },
                    'mobile-developer': {
                        name: 'Разработчик (IOS, Android) мобильных приложений',
                        description: 'Мы ищем увлечённого и грамотного технаря, не боящегося сложных, неординарных задач в сильнейшей команде. Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри компании.',
                    },
                    'flutter-developer': {
                        name: 'Разработчик <span class="text-blue">Flutter</span>',
                        description: 'Увлечен кросплатформенной разработкой? Умеешь превращать идеи в код? Если ты знаешь\n' +
                            'специфику и особенности различных мобильных операционных систем и браузеров, то эта\n' +
                            'вакансия для тебя!\n' +
                            'Тебе предстоит разрабатывать современные веб и мобильные приложения совместно с\n' +
                            'командой, поддерживать и развивать существующее решение.',
                        requirements: [
                            '<div class="text-blue">Обязанности</div>' +

                            '–  Разработка Flutter/Android приложений</br>' +

                            '<div class="text-blue">Требования</div>' +

                            '–  Опыт работы от 1 года (рассматриваем так же программистов без опыта работы в компаниях, но с опытом самостоятельного программирования);</br>' +
                            '–  Обязательно наличие имеющихся работ (портфолио);</br>' +
                            '–  Способность воспринимать большие объемы информации, готовность к обучению, умение работать в команде.</br>' +

                            'Обязательное знание:</br>' +

                            '–  Flutter;</br>' +
                            '–  Технический английский (свободное чтение рекомендуется, чтение со словарем - обязательно);</br>' +
                            '–  Знание Json/XML, REST, rxdart, Dio, Firebase;</br>' +
                            '–  Знание систем контроля версий (Git);</br>' +
                            '–  Опыт работы с Jira, YouTrack;</br>' +
                            '–  Дополнительно приветствуется знание Android SDK/Java.</br>' +

                            '<div class="text-blue">Основными задачами будут</div>' +

                            '–  Создание приложений и сайтов на Flutter;</br>' +
                            '–  Анализ новых технологий на предмет их внедрения.</br>' +

                            '<div class="text-blue">Условия</div>' +

                            '–  С вас высокий результат и четкое выполнение всех задач в срок;</br>' +
                            '–  Испытательный срок;</br>' +
                            '–  Рассматриваются кандидаты только на полный день;</br>' +
                            '–  Конкурентная заработная плата (белая), официальное трудоустройство;</br>' +
                            '–  Годовые и ежемесячные премии по результатам работы;</br>' +
                            '–  Обучение и тренировки (внутри компании, а также посещение бизнес-семинаров, тренингов);</br>' +
                            '–  Мы ищем тех, кто реально хочет работать. Тех, кому важен рост знаний и компетенций. Тех, для кого работа – это важная часть жизни для достижения успеха и самодостаточности;</br>' +
                            '–  Любителей создавать видимость работы, просьба не беспокоиться;</br>' +
                            '–  Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри компании.'
                        ]
                    },
                    'cpp-developer': {
                        name: 'Разработчик <span class="text-blue">C++</span>',
                        description: 'Тебе нравится разработка низкоуровневого ПО? Ты логичен и внимателен к мелочам и\n' +
                            'имеешь знания и навыки разработки ПО под различные операционные системы и\n' +
                            'платформы? Тебе предстоит работать с различными программными и аппаратными\n' +
                            'средствами защиты информации, как на рабочих станциях так и серверных платформах. Мы\n' +
                            'ждем тебя в нашей компании!',
                        requirements: [
                            '<div class="text-blue">Обязанности</div>' +

                            '–  Разработка системы Дистанционного Банковского Обслуживания</br>' +

                            '<div class="text-blue">Ты должен знать</div>' +

                            'Обязательно:</br>' +

                            '–  С или С++</br>' +
                            '–  Понимание технологий: API Win32, методы IPC, интеграция приложений, написанных на разных языках;</br>' +
                            '–  Опыт: от 1 года;</br>' +
                            '–  Хорошие навыки (чтение технической документации) английского языка;</br>' +
                            '–  Умение работать в команде и решать задачи самостоятельно.</br>' +

                            'Приветствуется:</br>' +

                            '–  Знание фреймворка QT, опыт разработки GUI-приложений на нем;</br>' +
                            '–  Опыт написания приложений на низкоуровневых языках для Windows, Linux;</br>' +
                            '–  Опыт работы с инфраструктурой открытых ключей (PKI);</br>' +
                            '–  Опыт работы с отечественными СКЗИ.</br>' +

                            '<div class="text-blue">Условия</div>' +

                            '–  С вас высокий результат и четкое выполнение всех задач в срок;</br>' +
                            '–  Испытательный срок;</br>' +
                            '–  Рассматриваются кандидаты только на полный день;</br>' +
                            '–  Конкурентная заработная плата (белая), официальное трудоустройство;</br>' +
                            '–  Годовые и ежемесячные премии по результатам работы;</br>' +
                            '–  Обучение и тренировки (внутри компании, а также посещение бизнес-семинаров, тренингов);</br>' +
                            '–  Мы ищем тех, кто реально хочет работать. Тех, кому важен рост знаний и компетенций. Тех, для кого работа – это важная часть жизни для достижения успеха и самодостаточности;</br>' +
                            '–  Любителей создавать видимость работы, просьба не беспокоиться;</br>' +
                            '–  Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри компании.'
                        ]
                    },
                    'manual-test': {
                        name: 'Специалист по <span class="text-blue">тестированию</span> (ручное тестирование)',
                        description: 'Ты умеешь испытывать надежность и удобство продукта на этапе разработки, выявлять ошибки и выяснять откуда они взялись? Тебе предстоит продумывать стратегию тестирования,  прогнозировать сбои, проверять приложение или фичу на наличие недочетов и готовить отчеты о результатах тестирования. </br>\n'
                            + 'Мы ждем тебя в нашей компании!',
                        requirements: [
                            '<div class="text-blue">Обязанности</div>' +

                            '–  Выполнение ручного тестирования;</br>' +
                            '–  Проведение функционального и регрессионного тестирования;</br>' +
                            '–  Разработка и поддержание тест-кейсов в актуальном состоянии;</br>' +
                            '–  Тестирование требований;</br>' +
                            '–  Анализ логов, оформление дефектов в bug-трекинговых системах;</br>' +
                            '–  Взаимодействие со смежными командами в рамках тестирования.</br>' +

                            '<div class="text-blue">Требования</div>' +

                            '–  Знание принципов и методов тестирования;</br>' +
                            '–  Знание тест-дизайна, умение писать тест-кейсы;</br>' +
                            '–  Знание SQL(на базовом уровне);</br>' +
                            '–  Опыт функционального тестирования мобильных и веб-приложений;</br>' +
                            '–  Опыт тестирования требований.</br>' +

                            '<div class="text-blue">Условия</div>' +

                            '–  С вас высокий результат и четкое выполнение всех задач в срок;</br>' +
                            '–  Испытательный срок;</br>' +
                            '–  Рассматриваются кандидаты только на полный день;</br>' +
                            '–  Конкурентная заработная плата (белая), официальное трудоустройство;</br>' +
                            '–  Годовые и ежемесячные премии по результатам работы;</br>' +
                            '–  Обучение и тренировки (внутри компании, а также посещение бизнес-семинаров, тренингов);</br>' +
                            '–  Мы ищем тех, кто реально хочет работать. Тех, кому важен рост знаний и компетенций. Тех, для кого работа – это важная часть жизни для достижения успеха и самодостаточности;</br>' +
                            '–  Любителей создавать видимость работы, просьба не беспокоиться;</br>' +
                            '–  Дружный коллектив единомышленников, корпоративные мероприятия и праздники внутри компании.'
                        ],
                    },
                }
            },
            docs: {
                title: 'Документы',
                metaTitle: 'Документы',
                downloadButton: 'Скачать',
                blocks: {
                    licenseReports: '<span class="text-blue">Лицензии </span> и отчеты',
                    presentations: '<span class="text-blue">Презентации </span> IsFront 2.0',
                    certificates: 'Партнерские <span class="text-blue"> сертификаты</span>',
                    letters: 'Благодарственные <span class="text-blue"> письма</span>'
                }
            },
            demo: {
                title: 'Демо-версии',
                metaTitle: 'Демо-версии',
                physicalTitle: 'Для <span class="text-blue">частных клиентов</span> банка',
                corporateTitle: 'Для <span class="text-blue">бизнес клиентов</span> банка',
                description: 'Ознакомьтесь с нашим кликабельным прототипом, чтобы получить полноценный пользовательский опыт.',
                buttons: {
                    web: '<span>Смотреть веб&#8209;версию</span>',
                    mp: 'Смотреть мобильную версию',
                    figmaWeb: 'Веб-версия',
                    figmaMP: 'Мобильная версия'
                },
                videosID: {
                    physicalWeb: '',
                    physicalMp: '',
                    corporateWeb: '',
                    corporateMp: ''
                }
            },
            order: {
                title: 'ПОЛУЧИТЬ isFront 2.0',
                metaTitle: 'Получить isFront 2.0',
                formTitle: 'Ваше <span class="text-blue">новое ДБО</span>',
                subtitle: 'Получите дизайн-проект и предварительный расчёт ДБО для Вашего банка'
            },
            modal: {
                formTitle: 'Оставить заявку',
                successTitle: 'Заявка отправлена',
                formFieldName: 'Имя',
                formFieldEmail: 'E-mail',
                formFieldPhone: 'Телефон',
                formAttachFiles: 'Прикрепить резюме',
                formWishVacancy: 'Желаемая вакансия',
                formButton: 'Отправить',
                okButton: 'Вернуться на сайт',
                formPolice: 'Нажимая на кнопку, вы принимаете <a class="link underline" target="_blank" href="assets/docs/policy_safety.pdf">политику по обработке персональных данных ООО “Информационные системы”</a>',
                errorSubmitForm: 'При отправке формы возникли ошибки',
                success: {
                    title: 'Заявка отправлена',
                    text: 'Спасибо за вашу заявку!<br>' +
                        'Мы свяжемся с вами в течение рабочего дня.',
                    button: 'Вернуться на главную'
                },
                successSubmitForm: 'Спасибо за вашу заявку!<br /> Мы свяжемся с вами в течение рабочего дня.',
                errors: {
                    name: {
                        null: 'Пожалуйста, введите ваше имя',
                        invalid: 'Имя введено некорректно'
                    },
                    vacancy: {
                        null: 'Введите желаемую вакансию',
                    },
                    phone: {
                        null: 'Введите номер телефона',
                    },
                    email: {
                        null: 'Введите адрес электронной почты',
                        invalid: 'Адрес электронной почты введен неверно'
                    },
                    attachments: {
                        serverError: 'Не удалось загрузить файл',
                        null: 'Прикрепите резюме'
                    }
                }
            },
        },
        footer: {
            title: {
                main: 'Обсудим ваш проект?',
                company: 'Хочешь работать у нас?',
                vacancies: 'Нет подходящей вакансии?'
            },
            info: {
                main: 'Получите дизайн-проект и предварительный расчёт ДБО для Вашего банка.',
                company: 'Мы рады талантливым специалистам. Если ты разработчик, аналитик, маг или дизайнер, то будем рады видеть тебя в нашей команде.',
                vacancies: 'Присылай свое резюме – мы всегда в поиске специалистов и обязательно его рассмотрим. А как только подходящая вакансия откроется – мы обязательно свяжемся с тобой!'
            },
            buttonText: {
                main: 'Получить isFront 2.0',
                company: 'Смотреть вакансии',
                vacancies: 'Оставить заявку'
            },
            buttonLink: {

            },
            address: 'Екатеринбург, Горького 65',
            phone: '+7 343 272 08 10'
        }
    }
};
